<template>
  <!--begin::Section-->
  <div class="pb-10">
    <!--begin::Heading-->
    <h1 class="anchor fw-bolder mb-5" id="overview">
      <a href="#overview"></a>
      Overview
    </h1>
    <!--end::Heading-->

    <!--begin::Block-->
    <div class="py-5">
      Indicator is a custom component used in combination with
      <a
        href="https://getbootstrap.com/docs/5.0/components/buttons/"
        class="fw-bold"
        >Bootstrap Spinners</a
      >,
      <a
        href="https://getbootstrap.com/docs/5.0/components/spinners/"
        class="fw-bold"
        >Bootstrap Spinners</a
      >&nbsp; and other components to provide a progress indication toggling for
      a user action.
    </div>
    <!--end::Block-->
  </div>
  <!--end::Section-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "overview",
  components: {}
});
</script>
